<template>
  <div v-if="studyPdf || studyImg">
    <div v-if="studyPdf" class="pdf-container">
      <header>{{ currentPage }}/{{ pageCount }}</header>
      <section>
        <div>
          <v-btn depressed small color="primary" :disabled="currentPage === 1" @click="currentPage--">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <vue-pdf :src="studyPdf"
                 @num-pages="pageCount = $event"
                 @page-loaded="loaded"
                 :page="currentPage"
        />
        <div>
          <v-btn depressed small color="primary" :disabled="currentPage === pageCount" @click="currentPage++">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </section>
      <footer>
        <a :href="studyPdf">
          <v-icon :aria-label="$t('presentation.study.download')">mdi-download</v-icon>
          {{ $t('presentation.study.download') }}
        </a>
      </footer>
    </div>

    <v-img :src="studyImg && !loading"
           :alt="`$t('presentation.study.text')`"
           v-else-if="studyImg"
    />

    <v-progress-circular indeterminate color="primary" v-else/>
  </div>

  <p v-else>{{ $t('presentation.study.nodata') }}</p>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// TODO: faire une page d'erreur si le projet n'est pas sélectionné
export default {
  name: 'Presentation',

  components: {
    VuePdf: () => import('vue-pdf'),
  },

  data: () => ({
    currentPage: 1,
    pageCount: 0,
    studyFileURL: null,
    studyImgURL: null,
    loading: false,
  }),

  computed: {
    ...mapGetters([
      'userConnected',
      'studyImageURL',
    ]),
    studyPdf () {
      if (this.userConnected.studySelected && this.userConnected.studySelected.studyPdf) {
        return this.userConnected.studySelected.studyPdf
      }

      return false
    },
    studyImg () {
      return this.userConnected.studySelected
             ? this.userConnected.studySelected.studyImg
             : false
    },
  },

  mounted () {
    if (this.userConnected.studySelected && this.userConnected.studySelected.studyImg) {
      this.loading = true

      Promise.all([
        this.loadStudyImageURL(this.userConnected.studySelected.id),
      ])
             .then(() => {
               this.studyImgURL = this.studyImageURL
               this.loading = false
             })
    }
  },

  methods: {
    ...mapActions(['loadStudyImageURL']),
    loaded (event) {
      this.currentPage = event
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/style";

img {
  width: 100%;
}

.pdf-container {
  header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    font-size: 25px;
    background-color: $whiteTransparent;
  }

  section {
    display: grid;
    grid-template-columns: 50px 1fr 50px;

    > div {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $whiteTransparent;
    }
  }

  footer {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $whiteTransparent;

    a {
      text-decoration: none;
      color: black;
    }
  }
}
</style>
